























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import { setStatus } from '@/api/console/workflows'
import { getWorkflow, deleteWorkflow, updateWorkflow } from '@/api/consoleApi/workflows'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import VsWorkflowSettingsCard from '@/modules/workflows/components/VsWorkflowSettingsCard/Index.vue'
import VsWorkflowTimelineCard from '@/modules/workflows/components/VsWorkflowTimelineCard/Index.vue'
import VsWorkflowListCard from '@/modules/workflows/components/VsWorkflowListCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { Workflow, WorkflowMessage } from '@/api/consoleApi/dto/workflows.dto'
import { get } from 'lodash'
import { getCustomField } from '@/api/consoleApi/customFields'
import { WorkflowsModule } from '@/store/modules/workflows'
import { getCampaign } from '@/api/consoleApi/campaigns'
import EditNameModal from '@/modules/workflows/components/EditNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { getStoreProductById } from '@/api/consoleApi/stores'
import { getFormById } from '@/api/leadplus/forms'
import axios from 'axios'

@Component({
    name: 'EditWorkflow',
    components: {
        VsSectionHeader,
        VsContainer,
        VsTabsHeading,
        VsDropdownButton,
        VsWorkflowTimelineCard,
        VsWorkflowSettingsCard,
        VsWorkflowListCard,
        EditNameModal,
        VsConfirm,
        VsLoader,
    },
})
export default class extends Vue {
    loading = false
    workflow: Workflow | null = null
    $refs!: any
    private missingSteps: string[] = []

    private tabOpen = ''

    get isEditable () {
        return this.workflow?.status === 'draft' || this.workflow?.status === 'pause'
    }

    get activeTab () {
        return this.$route.name
    }

    get messages () {
        return this.workflow?.messages?.data || []
    }

    get tabs () {
        const tabs = [
            {
                label: 'Riepilogo',
                id: 'editWorkflow',
                to: {
                    name: 'editWorkflow',
                },
            },
            {
                label: 'Statistiche',
                id: 'workflowStatistics',
                to: {
                    name: 'workflowStatistics',
                },
            },
            {
                label: 'Coda di invio',
                id: 'workflowQueue',
                to: {
                    name: 'workflowQueue',
                },
            },
        ]

        return tabs
    }

    get workflowId () {
        return this.$route?.params?.workflowId || ''
    }

    openTab (tabName: string) {
        this.tabOpen = tabName
    }

    beforeMount () {
        this.getWorkflow()
    }

    validateCards () {
        const missingSteps = []
        if (this.$refs.vsWorkflowSettingsCard.cardStatus !== 'success') { missingSteps.push('settings') }
        if (this.$refs.vsWorkflowTimelineCard.workflowTimelineStatus !== 'success') { missingSteps.push('timeline') }
        this.missingSteps = missingSteps
        return this.missingSteps.length === 0
    }

    async getWorkflow () {
        this.missingSteps = []
        try {
            const resp = await getWorkflow(this.workflowId, { include: 'recipient.store,messages,messages.sms,messages.email.footer' })
            this.workflow = resp.data.data
            if (this.workflow?.messages?.data) {
                this.workflow.messages.data = this.workflow?.messages.data.map(el => {
                    if (el.email) {
                        el.email.data.email_name = el.name
                    }
                    if (el.sms) {
                        el.sms.data.name = el.name
                    }

                    el.hourDelay = this.calculateHourDelay(el)

                    return el
                })
            }
        } catch (e) {
            const code = get(e, 'response.status', null)
            if (code === 404) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: 'Marketing automation non trovata',
                    aspect: VsToastAspectEnum.alert,
                })
                this.$router.replace({ name: 'workflows' })
                return
            }
            console.log(e)
        }
    }

    private calculateHourDelay (workflowMessage: WorkflowMessage) {
        const as = get(workflowMessage, 'trigger.parameter.as', 'days')
        const wait = get(workflowMessage, 'trigger.parameter.wait', 'wait')

        if (as === 'immediatily') return 0
        if (as === 'hours') return wait
        if (as === 'days') return wait * 24
        if (as === 'week') return wait * 168
    }

    @Watch('workflow.settings', { immediate: true, deep: true })
    async getSettingsDatas (settings: any) {
        if (!settings) return

        if (this.workflow?.trigger_name === 'updateSubscriber') {
            const selectedCustomFields: any[] = []
            for (const cf of settings.custom_field_ids) {
                try {
                    const resp = await getCustomField(cf)
                    selectedCustomFields.push(resp.data.data)
                } catch (e) {
                    console.log(e)
                }
            }
            WorkflowsModule.SET_SELECTED_CUSTOM_FIELDS(selectedCustomFields)
            // settings.custom_field_ids
        } else {
            WorkflowsModule.SET_SELECTED_CUSTOM_FIELDS([])
        }

        if (this.workflow?.trigger_name === 'onDate' && this.workflow?.settings?.custom_field_id) {
            try {
                const resp = await getCustomField(this.workflow.settings.custom_field_id)
                WorkflowsModule.SET_SELECTED_DATE_CUSTOM_FIELD(resp.data.data)
            } catch (e) {
                WorkflowsModule.SET_SELECTED_DATE_CUSTOM_FIELD(null)
                console.log(e)
            }
        } else {
            WorkflowsModule.SET_SELECTED_DATE_CUSTOM_FIELD(null)
        }

        if (this.workflow?.trigger_name === 'flowEnd' && this.workflow?.settings?.workflow_id) {
            try {
                const resp = await getWorkflow(this.workflow?.settings?.workflow_id)
                WorkflowsModule.SET_SELECTED_WORKFLOW(resp.data.data)
            } catch (e) {
                WorkflowsModule.SET_SELECTED_WORKFLOW(null)
            }
        } else {
            WorkflowsModule.SET_SELECTED_WORKFLOW(null)
        }

        if (this.workflow?.trigger_name === 'ecommerceProductPurchased' && this.workflow?.settings?.value) {
            try {
                const resp = await getStoreProductById(this.workflow?.recipient?.data.store.data.id, this.workflow?.settings?.value)
                WorkflowsModule.SET_SELECTED_PRODUCT(resp.data)
            } catch (e) {
                WorkflowsModule.SET_SELECTED_PRODUCT(null)
            }
        } else {
            WorkflowsModule.SET_SELECTED_PRODUCT(null)
        }
        if (this.workflow?.trigger_name === 'campaignActivity' && this.workflow?.settings?.campaign_id) {
            try {
                const resp = await getCampaign(this.workflow?.settings?.campaign_id)
                WorkflowsModule.SET_SELECTED_CAMPAIGN(resp.data.data)
            } catch (e) {
                WorkflowsModule.SET_SELECTED_CAMPAIGN(null)
            }
        } else {
            WorkflowsModule.SET_SELECTED_CAMPAIGN(null)
        }

        if (this.workflow?.trigger_name === 'form' && this.workflow?.settings?.form_id) {
            try {
                const resp = await getFormById(this.workflow?.settings?.form_id)
                WorkflowsModule.SET_SELECTED_FORM(resp.data)
            } catch (e) {
                WorkflowsModule.SET_SELECTED_FORM(null)
            }
        } else {
            WorkflowsModule.SET_SELECTED_FORM(null)
        }
    }

    get badgeStatusAspect () {
        switch (this.workflow?.status || null) {
            case 'ready':
                return 'success'
            case 'pause':
                return 'warning'
            case 'draft':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.workflow?.status) return ''
        switch (this.workflow?.status || null) {
            case 'pause':
                return 'pause'
            case 'ready':
                return 'double-check'
            case 'draft':
                return 'edit'
        }
    }

    async editAutomationName (event: any) {
        this.loading = true
        try {
            await updateWorkflow(this.workflow?.id || '', {
                name: event.name,
            })
            this.getWorkflow()
            this.$root.$vsToast({
                heading: 'Nome modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del nome',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async activeWorkflow () {
        if (!this.validateCards()) {
            this.$root.$vsToast({
                heading: 'Impossibile attivare il flusso',
                message: 'Ci sono errori da correggere prima di attivare il flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        } else {
            this.loading = true
            try {
                await setStatus(this.workflowId, 'ready')
                this.$root.$vsToast({
                    heading: 'Flusso attivato con successo',
                    timeout: 3000,
                    aspect: VsToastAspectEnum.success,
                })
                this.getWorkflow()
            } catch (e) {
                const heading = 'Errore durante l\'attivazione del flusso'
                let message = ''
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 400 && e.response.data.errors?.sender) {
                        message = '- ' + e.response.data.errors?.sender[0] + '.<br/>'
                    }
                    if (e.response?.status === 400 && e.response.data.errors?.segments) {
                        message += ' - Sono presenti profilazioni non più esistenti sulla lista del flusso. <br/>'
                    }
                    if (e.response?.status === 400 && e.response.data.errors?.subject) {
                        message += ' - Devi compilare il campo "Oggetto" della mail.'
                    }
                }
                this.$root.$vsToast({
                    heading,
                    message,
                    timeout: 5000,
                    aspect: VsToastAspectEnum.alert,
                })
                console.log(e)
            }
            this.loading = false
        }
    }

    async pauseWorkflow () {
        this.loading = true
        try {
            await setStatus(this.workflowId, 'pause')
            this.$root.$vsToast({
                heading: 'Flusso messo in pausa con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getWorkflow()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la messa in pausa del flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteWorkflow () {
        if (!this.workflow) return ''
        try {
            await this.$refs.confirmDelete.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await deleteWorkflow(this.workflowId)
            this.$root.$vsToast({
                heading: 'Flusso eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'workflows',
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione del flusso',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private async openConfirmDraft () {
        if (!this.workflow) return
        try {
            await this.$refs.confirmDraft.openConfirm()
        } catch (e) {
            return
        }
        this.pauseWorkflow()
    }
}
